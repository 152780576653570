export const environment = {
  production: false,
  profileName : 'stage',
  apiEndpoint: 'https://api.lessons.stage.callanworks.co.uk/api',
  staticPagesBase: 'https://api.lessons.stage.callanworks.co.uk',
  fileEndpoint: 'https://api.lessons.stage.callanworks.co.uk/files',
  bookingEndpoint: 'https://api.booking.stage.callanworks.co.uk/api',
  authEndpoint: 'https://casa-dev.callanonline.com',
  serverBase: 'https://col2.stage.callanworks.co.uk',
  casaClientId: 'fa9fhnawefnhlobvdaoihv90whfwefbagerge',
  casaRoot: 'https://casa-dev.callanonline.com',
  cspaApiEndpoint: 'https://cspa-dev.callanonline.com',
  cspaUi: 'https://stage.cspa.callanworks.co.uk',
  cspaSocketEndpoint: 'https://cspa-dev.callanonline.com/cspa-websocket',
  lessonWsEndpoint: 'wss://api.lessons.stage.callanworks.co.uk/ws',
  cspaWsEndpoint: 'wss://cspa-dev.callanonline.com/cspa-websocket',

  areaServerBase: 'https://student-area.callanworks.co.uk',

  analyticsUA: 'UA-69580833-1',
  adsTrackingCode: 'AW-977652603',
  adsTransactionLabel: 'XSmtCOa28JoBEPuWl9ID',
  pusher: {
    key: '2a010f7970d6884d1723',
    cluster: 'eu'
  },

  callanonlineId : 1,
  supportedUiLanguages: ['en', 'pl', 'es', 'it', 'de' , 'ru' /*, 'ja'*/],
  scheduleBridgeEnabled: false
};
