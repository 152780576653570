import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {PlatformLocation} from '@angular/common';
import {Router} from '@angular/router';
import {AuthResponse} from "../../model/auth/AuthResponse";
import {environment} from "../../../environments/environment";
import {tap} from "rxjs/operators";

export enum UserRole {
  // user is unauthorized
  Unauthorized = "UNAUTHORIZED",
  Student = "STUDENT",
  Teacher = "TEACHER",
  Admin = "ADMIN",
  Manager = "MANAGER",
  // Role is unknown for the application or impossible to recognize, threat it as Unauthorized
  Unknown = "UNKNOWN"
}

export interface AuthService {
  initialize(): void;
  clear(): void;
  startLogin(stateUrl: string);
  authorizeCode(code): Observable<AuthResponse>;
  refreshToken(): Observable<AuthResponse>;
  getAccessToken(): string;
  getAccessCode(): Observable<String>
  resolveState(state: string): string;
  isTokenValid(): boolean;
  logout(): void;
  startRegistration(stateUrl: string);
  getUserRole(): Observable<UserRole>;
  getSchoolId(): number;
  getStudentId(): number;
  getTeacherId(): number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthServiceProvider {
  constructor(private http: HttpClient) {}

  private resultService: AuthService;

  get(): Observable<AuthService> {
    if (this.resultService) { return of(this.resultService); }
    this.resultService = new AuthImplService(this.http);
    this.resultService.initialize();
    return of(this.resultService);
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthImplService implements AuthService {

  private readonly accessTokenLocalStorageName = 'accessToken';
  private readonly refreshTokenLocalStorageName = 'refreshToken';
  private oauthEndpoint = environment.authEndpoint;
  private clientId = environment.casaClientId;
  private serverBase = environment.serverBase;
  private redirectUrl = `${this.serverBase}/oauth`;


  private accessTokenValue: string;
  private refreshTokenValue: string;
  private tokenClaims: any = null;

  constructor(private http: HttpClient) { }

  initialize() {
    this.accessTokenValue = localStorage.getItem(this.accessTokenLocalStorageName);
    this.refreshTokenValue = localStorage.getItem(this.refreshTokenLocalStorageName);
    if (!this.isTokenValid()) {
      this.clear();
      return;
    }

    this.extractTokenData();
  }

  private extractTokenData() {
    this.tokenClaims = JSON.parse(atob(this.accessTokenValue.split("\.")[1]));
  }

  authorizeCode(code): Observable<AuthResponse> {
    this.clear();
    return this.askForAccessToken(code)
      .pipe(
        tap(() => this.extractTokenData())
      );
  }

  clear(): void {
    localStorage.removeItem(this.accessTokenLocalStorageName);
    localStorage.removeItem(this.refreshTokenLocalStorageName);
    this.tokenClaims = null;
  }

  private askForAccessToken(code: string): Observable<AuthResponse> {
    const codeRequest = {
      code: code,
      client_id: this.clientId,
      redirect_uri: this.redirectUrl,
      grant_type: "authorization_code"
    };

    const formData = new HttpParams({ fromObject: codeRequest}).toString()
    return this.http.post<AuthResponse>(
      `${this.oauthEndpoint}/oauth/v2/token`,
      formData,
      {headers : new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })})
      .pipe(tap(tokenResponse => this.saveToken(tokenResponse)));
  }

  private saveToken(resp: AuthResponse): void {
    this.accessTokenValue = resp.access_token;
    localStorage.setItem(this.accessTokenLocalStorageName, this.accessTokenValue);
    this.refreshTokenValue = resp.refresh_token;
    localStorage.setItem(this.refreshTokenLocalStorageName, this.refreshTokenValue);
  }

  getAccessToken(): string {
    return this.accessTokenValue;
  }

  getAccessCode(): Observable<string>{
    return this.http.post<string>(`${this.oauthEndpoint}/api/v2/self/authorization`, {}, {
      params: {
      client_id: this.clientId,
      redirect_uri: this.redirectUrl
      },
      responseType: 'text' as 'json'
    })
  }

  getSchoolId(): number {
    const subject: string[] = this.getSubject() || null;
    if (subject[0] !== 'school') throw Error("is not student role");
    return Number(subject[1]);
  }

  getStudentId(): number {
    const subject: string[] = this.getSubject() || null;
    if (subject[0] !== 'school' || subject[2] !== 'student') throw Error("is not student role");
    return Number(subject[3]);
  }

  getTeacherId(): number {
    const subject: string[] = this.getSubject() || null;
    if (subject[0] !== 'school' || subject[2] !== 'teacher') throw Error("is not teacher role");
    return Number(subject[3]);
  }

  getUserRole(): Observable<UserRole> {
    if (!this.isTokenValid()) return of(UserRole.Unauthorized);
    const subject = this.getSubject();
    if (subject[2] === 'student')  return of(UserRole.Student);
    if (subject[2] === 'teacher')  return of(UserRole.Teacher);
    if (subject[2] === 'manager')  return of(UserRole.Manager);
    if (subject[0] === 'admin')  return of(UserRole.Admin);
    return of(UserRole.Unknown);
  }

  isTokenValid(): boolean {
    const accessToken = AuthImplService.decomposeToken(this.accessTokenValue);
    if (!accessToken) {
      return false;
    }
    return accessToken.data.sub;
  }

  private static decomposeToken(token: string): any {
    const res: any = {};
    if (!token) {
      return null;
    }
    const splitted = token.split('.');
    res.alg = JSON.parse(atob(splitted[0]));
    res.data = JSON.parse(atob(splitted[1]));
    return res;
  }

  private getSubject(): string[] {
    return this.tokenClaims? this.tokenClaims["sub"]?.split("/") : null;
  }

  logout(): void {
    const currentUrl = this.serverBase + '/logout';
    const logoutUrl = environment.authEndpoint + '/oauth/logout?redirect_uri=' + encodeURIComponent(currentUrl);

    this.clear();
    window.location.href = logoutUrl;
  }

  refreshToken(): Observable<AuthResponse> {
    const codeRequest = {
      refresh_token: this.refreshTokenValue,
      client_id: this.clientId,
      grant_type: "refresh_token"
    };

    const formData = new HttpParams({ fromObject: codeRequest}).toString()
    return this.http.post<AuthResponse>(
      `${this.oauthEndpoint}/oauth/v2/token`,
      formData,
      {headers : new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })})
      .pipe(tap(tokenResponse => this.saveToken(tokenResponse)));
  }

  resolveState(state: string): string {
    const paramsExpr = /\[:([^\]]+)\]/g;
    const path = state;
    let myArray: any = null;
    let result = '';
    let startIdx = 0;
    while ((myArray = paramsExpr.exec(path)) !== null) {
      result += path.substring(startIdx, myArray.index);
      if (myArray[1] === "studentId") result += this.getStudentId();
      else if (myArray[1] === "teacherId") result += this.getTeacherId();
      startIdx = paramsExpr.lastIndex;
    }
    if (startIdx < state.length) {
      result += path.substring(startIdx);
    }
    return result;
  }

  startLogin(stateUrl: string) {
    window.location.href = this.oauthEndpoint
      + '/oauth/v2/authorize?' + this.constructOauth(stateUrl);
  }

  private constructOauth(stateUrl: string) {
    const stateStr = encodeURIComponent(btoa(stateUrl));
    const redirectUrl = encodeURIComponent(this.redirectUrl);
    const role = encodeURIComponent("student|teacher");

    return `response_type=code&client_id=${this.clientId}&state=${stateStr}&redirect_uri=${redirectUrl}&role=${role}`;
  }

  startRegistration(stateUrl: string) {
    const oauth = environment.authEndpoint
      + '/oauth/school/' + environment.callanonlineId + '/register?' + this.constructOauth(stateUrl);
    window.location.href = oauth;
  }
}
