import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { AnalyticsService } from './services/analytics.service';
import { LocaleManagerService } from './services/locale-manager.service';
import { ThemeService } from './services/theme.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
/*
Main application component
*/
export class AppComponent implements OnInit {

  title = 'app';
  profileName = environment.profileName;


  constructor(
    private analytics: AnalyticsService,
    private themeService: ThemeService,
    /* import it to allow language detection */
    localeManager: LocaleManagerService
  ) {
  }

  ngOnInit(): void {
    this.analytics.init();
    this.themeService.initialize()
  }

}
