<div class="min-vh-100 bg-radial">
 <div class="bg-secondary mb-5">
    <div class="container">
      <nav class="navbar navbar navbar-expand navbar-dark">
        <a class="navbar-brand col-3 col-sm-2 col-md-2" href="#" routerLink="/teacher/{{teacherId}}/dashboard"><img [src]="themeService.getLogoPath()" class="img-fluid" alt="Callan Online Logo"/></a>

        <!--Add here-->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarWebsite">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!--Add here -->
        <div class="collapse navbar-collapse col-sm-10" id="navbarWebsite">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="user-dropdown" data-bs-toggle="dropdown">
                  <i class="fa fa-user"></i>
              </a>
              <div class="dropdown-menu">
                  <a class="dropdown-item" href="#" routerLink="/teacher/{{teacherId}}/profile">
                    <i class="far fa-user  me-2 text-primary"></i>
                    <span class="text-muted">Profile</span>
                  </a>
                  <a class="dropdown-item" type="button" (click)="themeService.onThemeChange(theme)">
                    <span *ngIf="theme === 'light'" class="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="15" viewBox="2 0 17 20">
                        <path fill="var(--primary)" d="M10 17q-2.917 0-4.958-2.042Q3 12.917 3 10q0-2.917 2.042-4.958Q7.083 3 10 3q.271 0 .531.021.261.021.531.062-.812.605-1.291 1.5-.479.896-.479 1.917 0 1.771 1.218 2.99 1.219 1.218 2.99 1.218 1.021 0 1.917-.479.895-.479 1.5-1.291.041.27.062.531.021.26.021.531 0 2.917-2.042 4.958Q12.917 17 10 17Zm0-1.5q1.708 0 3.104-.979 1.396-.979 2.042-2.563-.417.104-.823.177-.406.073-.823.073-2.375 0-4.042-1.666Q7.792 8.875 7.792 6.5q0-.417.073-.823.073-.406.177-.823-1.584.646-2.563 2.042Q4.5 8.292 4.5 10q0 2.292 1.604 3.896T10 15.5Zm-.292-5.208Z"/>
                      </svg>
                    </span>
                    <span *ngIf="theme === 'dark'" class="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="14" viewBox="1 0 19 20">
                        <path fill="var(--primary)" d="M10 12.5q1.042 0 1.771-.729.729-.729.729-1.771 0-1.042-.729-1.771Q11.042 7.5 10 7.5q-1.042 0-1.771.729Q7.5 8.958 7.5 10q0 1.042.729 1.771.729.729 1.771.729Zm0 1.5q-1.667 0-2.833-1.167Q6 11.667 6 10q0-1.667 1.167-2.833Q8.333 6 10 6q1.667 0 2.833 1.167Q14 8.333 14 10q0 1.667-1.167 2.833Q11.667 14 10 14Zm-8.25-3.25q-.312 0-.531-.219Q1 10.312 1 10q0-.312.219-.531.219-.219.531-.219h2q.312 0 .531.219.219.219.219.531 0 .312-.219.531-.219.219-.531.219Zm14.5 0q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219h2q.312 0 .531.219Q19 9.688 19 10q0 .312-.219.531-.219.219-.531.219ZM10 4.5q-.312 0-.531-.219-.219-.219-.219-.531v-2q0-.312.219-.531Q9.688 1 10 1q.312 0 .531.219.219.219.219.531v2q0 .312-.219.531-.219.219-.531.219ZM10 19q-.312 0-.531-.219-.219-.219-.219-.531v-2q0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531v2q0 .312-.219.531Q10.312 19 10 19ZM5.042 6.104 4 5.042q-.229-.209-.229-.511 0-.302.229-.531.208-.229.521-.229.312 0 .521.229l1.062 1.042q.229.229.229.531 0 .302-.229.531-.208.229-.521.229-.312 0-.541-.229ZM14.958 16l-1.062-1.042q-.229-.229-.229-.531 0-.302.229-.531.208-.229.521-.229.312 0 .541.229L16 14.958q.229.209.229.511 0 .302-.229.531-.229.229-.521.229-.291 0-.521-.229Zm-1.062-9.896q-.229-.208-.229-.521 0-.312.229-.541L14.958 4q.23-.229.521-.219.292.011.521.219.229.229.229.521 0 .291-.229.521l-1.042 1.062q-.229.229-.531.229-.302 0-.531-.229ZM4 16q-.229-.208-.229-.521 0-.312.229-.521l1.042-1.062q.229-.208.531-.208.302 0 .531.208.229.229.219.531-.011.302-.219.531L5.042 16q-.209.229-.511.229-.302 0-.531-.229Zm6-6Z"/>
                      </svg>
                    </span>
                    <span class="text-muted">
                      {{ themeService.getThemeName(theme) }}
                    </span>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" (click)="logout()">
                    <i class="fas fa-sign-out-alt me-2 text-primary"></i>
                    <span class="text-muted">Logout</span>
                  </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>

  <div class="container py-5">
    <router-outlet></router-outlet>
  </div>
</div>
